import { useContext } from 'react';
import './Header.css';
import ReactCountryFlag from "react-country-flag"
import { Language, LanguageContext } from '../../contexts/language.context';


export const Header = () => {

    const { language, setLanguage } = useContext(LanguageContext) || {}

    const changeLanguage = (lan: Language) => {
        if (setLanguage) {
            setLanguage(lan)
        }
        window.localStorage.setItem('language', lan)
    }
    console.log(language)
    return (
        <div className='header'>
            <div className='logo'>

            </div>
            <div className='flags'>




                <ReactCountryFlag
                    onClick={() => changeLanguage(Language.PL)}
                    className='flag'
                    countryCode={Language.PL}
                    svg
                    title="PL"
                    style={{
                        width: '40px',
                        height: '30px',
                    }}
                />

                <ReactCountryFlag
                    onClick={() => changeLanguage(Language.UA)}
                    className='flag'
                    countryCode={Language.UA}
                    svg
                    style={{
                        width: '40px',
                        height: '30px',
                    }}
                />

                <ReactCountryFlag
                    onClick={() => changeLanguage(Language.GB)}
                    className='flag'
                    countryCode={Language.GB}
                    svg
                    style={{
                        width: '40px',
                        height: '30px',
                    }}
                />
            </div>
        </div >
    )
}