import { ChangeEvent, useState } from 'react';
import { FaRegCircleXmark } from "react-icons/fa6";
import './PwdModal.css'
import { useNavigate } from 'react-router-dom';
import { get } from '../../functions';

interface ModalProps {
    id: string;
    setIsPasswordCorrect: Function;
    setApplication: Function;
}

export const PwdModal = (props: ModalProps) => {
    const { id, setIsPasswordCorrect, setApplication } = props
    const [password, setPassword] = useState<string>('')
    const navigate = useNavigate();

    const handleChangePwd = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }



    const getApplication = async () => {
        const response = await get(`/application/app-get-one?id=${id}&password=${password}`)

        if (response.id) {
            setApplication(response)
            setIsPasswordCorrect(true)
        } else (
            setPassword('')
        )
    }

    const handleClose = () => {
        navigate('/')
    }

    return (
        <div className="modal-bcg">
            <div className='modal-container'>
                <FaRegCircleXmark className='close-icon' onClick={handleClose} />
                <h3 className=''>Podaj Hasło</h3>
                <input className='input' type="password" value={password} onChange={handleChangePwd} />
                <button className='button' onClick={getApplication}>Zatwierdź</button>
            </div>
        </div>
    )
}


