import { useContext, useEffect, useState } from "react";
import { get, getFile } from "../../functions";
import { Loader } from "../Loader";
import { useTranslation } from 'react-i18next';
import './Application.css'
import { PwdModal } from "../PwdModal/PwdModal";
import { LanguageContext } from "../../contexts/language.context";



interface ApplicationProps {
    id: string;
    password: boolean;
}

const baseUrl = process.env.REACT_APP_API_URI;

export const Application = (props: ApplicationProps) => {
    const { images } = useContext(LanguageContext) || {}
    const { id, password } = props;
    const [icon, setIcon] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean>(password === false ? true : false);
    const [application, setApplication] = useState<any>(undefined)
    const { t } = useTranslation();

    useEffect(() => {
        const getLogo = async () => {
            const logo = await getFile(`/application/get-icon/${id}`)
            setIcon(logo)
            setIsLoading(false)
        }
        getLogo()
    }, [id])

    useEffect(() => {
        if (!application) {
            const getApp = async () => {
                const response = await get(`/application/app-get-one?id=${id}`)
                if (response.id)
                    setApplication(response)
            }
            getApp()

        }
    }, [id, isPasswordCorrect, application])

    const handleDownload = async () => {
            try {
                const downloadUrl = `${baseUrl}/application/get-file/${application.name}`; 
        
                // Tworzymy link do pobierania
                const link = document.createElement('a');
                link.href = downloadUrl;
        
                // Jeśli przeglądarka obsługuje atrybut "download", ustawiamy nazwę pliku
        
                // Dodajemy link do dokumentu
                document.body.appendChild(link);
        
                // Symulujemy kliknięcie na link
                link.click();
        
                // Usuwamy link z dokumentu
                document.body.removeChild(link);
            } catch (error) {
                console.error('Błąd podczas pobierania pliku:', error);
            }
    }

    const descriptionLines = application?.description.split('\n');
    return (
        <>
        
            {application && images ?
                <div className="application-page">
                    <h1 className="title">{application.name}</h1>
                    <img className="page-img" src={icon?.url} alt="logo" />
                    <button onClick={handleDownload} className="button">{t('applications.download')}</button>
                    { application?.description && <div className="configuration">
                        <h2 className="subtitle">{t('applications.configuration')}</h2>
                        {descriptionLines.map((line: any, index: any) => (
                            <p key={index}>{line}</p>
                        ))}
                    </div>}
                    <div className="configuration">
                        <h2 className="subtitle">{t('applications.instalationGuide')}</h2>
                        <p className="install_guide">1. {t('applications.downloadApp')}</p>
                        <p>2. {t('applications.followPictures')}</p>
                        <div className="install_img" style={{backgroundImage:  `url(${images[0]})`}}></div>
                        <div className="install_img" style={{backgroundImage:  `url(${images[1]})`}}></div>
                        <div className="install_img" style={{backgroundImage:  `url(${images[2]})`}}></div>
                        <div className="install_img" style={{backgroundImage:  `url(${images[3]})`}}></div>
                    </div>
                

                    {isLoading && <Loader />}
                </div> :
                <> {!isPasswordCorrect && <PwdModal id={id} setIsPasswordCorrect={setIsPasswordCorrect} setApplication={setApplication} />}</>
            }

        </>
    )
}