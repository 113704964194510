import { createContext, useCallback, useEffect, useState } from "react";
import { get } from "../functions";

import pl1 from '../assets/images/instalation/1pl.jpg';
import pl2 from '../assets/images/instalation/2pl.jpg';
import pl3 from '../assets/images/instalation/3pl.jpg';
import pl4 from '../assets/images/instalation/4pl.jpg';
import ua1 from '../assets/images/instalation/1ua.jpg';
import ua2 from '../assets/images/instalation/2ua.jpg';
import ua3 from '../assets/images/instalation/3ua.jpg';
import ua4 from '../assets/images/instalation/4ua.jpg';
import gb1 from '../assets/images/instalation/1gb.jpg';
import gb2 from '../assets/images/instalation/2gb.jpg';
import gb3 from '../assets/images/instalation/3gb.jpg';
import gb4 from '../assets/images/instalation/4gb.jpg';

export enum Language {
    PL = 'PL',
    UA = 'UA',
    GB = 'GB',
}

export interface Application {
    id: string,
    name: string,
    password: boolean,
    isVisible: string,

}
export type LanguageContextType = {
    language: Language,
    setLanguage: Function,
    applications: Application[],
    images: any[]

};

export const LanguageContext = createContext<LanguageContextType | null>(null)

type Props = { children: JSX.Element };

export const LanguageContextProvider = ({ children }: Props) => {
    const [language, setLanguage] = useState<Language>(window.localStorage.getItem('language') as Language);
    const [applications, setApplications] = useState<Application[]>([])
    const [images, setImages] = useState<any>([])

    const getApplications = useCallback(async () => {
        const apps = await get('/application/app-get-all')
        setApplications(() => apps)

    }, [])

    useEffect(() => {
        if (!language) {
            setLanguage(Language.UA)
        }
        getApplications()

        switch(language){
            case Language.PL: {
                setImages([pl1, pl2, pl3, pl4])
                break
            }

            case Language.GB: {
                setImages([gb1, gb2, gb3, gb4])
                break
            }

            case Language.UA: {
                setImages([ua1, ua2, ua3, ua4])
                break
            }
        }

    }, [language, getApplications])


    return (
        <LanguageContext.Provider value={{
            language: language,
            setLanguage: setLanguage,
            applications: applications,
            images: images,
        }}>
            {children}
        </LanguageContext.Provider>
    )
}