import React, { useCallback, useContext, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { Header } from './components/Header/Header'
import { LanguageContext } from './contexts/language.context';
import { Dashboard } from './components/pages/Dashboard/Dashboard';
import LanguageDetector from 'i18next-browser-languagedetector';
import common_pl from './locales/pl/translation.json';
import common_ua from './locales/ua/translation.json';
import common_gb from './locales/gb/translation.json';
import { Application } from './components/Application/Application';


function App() {
  const { language, applications } = useContext(LanguageContext) || {}
  const [routes, setRoutes] = useState<any[]>([])

  const setAppRoutes = useCallback(async () => {
    if (applications) {
      const rou: any[] = []
      for (let app of applications) {
        rou.push(<Route key={app.id} path={`/${app.name}`} element={<Application id={app.id} password={app.password} />} />)
      }
      setRoutes(rou)
    }
  }, [applications])

  useEffect(() => {
    setAppRoutes()
  }, [setAppRoutes])

  i18n
    .use(LanguageDetector)
    .init({
      resources: {
        PL: {
          translation: common_pl
        },
        UA: {
          translation: common_ua
        },
        GB: {
          translation: common_gb
        }
      },

      fallbackLng: 'pl',
      debug: true,
      interpolation: {
        escapeValue: false
      },
      lng: language // Ustawienie języka na podstawie zmiennej language
    });
  return (
    <div className="App">

      <Header />
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Routes>
            <Route path='/' element={<Dashboard />} />
            {routes}
          </Routes>

        </I18nextProvider>

      </BrowserRouter>

    </div>
  );
}

export default App;
