import { useTranslation } from 'react-i18next';
import './Dashboard.css';
import { useCallback, useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../../contexts/language.context';
import { getFile } from '../../../functions';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../Loader';

export const Dashboard = () => {
    const { applications } = useContext(LanguageContext) || {}
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true)


    const [sliderElements, setSliderElement] = useState<any[]>([])


    const setSlider = useCallback(async () => {
        const handleClickApp = (app: any) => {
            navigate(`/${app.name}`)
        }
        console.log(applications)
        if (applications) {
            setIsLoading(false)
            const apps: any[] = []
            for (let app of applications) {
                if (app.isVisible === 'tak') {
                    const logo = await getFile(`/application/get-icon/${app.id}`)
                    apps.push(<div key={app.id} className='list-element' onClick={() => handleClickApp(app)}>
                        <img className="element-img" src={logo.url} alt="logo" />
                        <div className="element-legend">{app.name}</div>
                    </div>)
                }

            }
            setSliderElement(apps)
        }
    }, [applications, navigate])

    useEffect(() => {
        setSlider()
    }, [applications, setSlider])


    return (
        <div className='dashboard'>
            <h1 className='title'>{t('dashboard.applications')}</h1>
            {sliderElements}
            {isLoading && <Loader />}
        </div>

    )
}